<template>
  <div>
    <sqr-notification
      v-for="error in errors"
      :key="error.id"
      :id="error.id"
      color="warning"
      :label="error.label"
      :label-raw="error.label ? null : error.message"
    />
  </div>
</template>

<script>
import SqrNotification from '@/sqrd/SqrNotification';

export default {
  name: 'ErrorNotifications',
  components: { SqrNotification },
  props: {
    errors: { type: Array, default: () => [] },
  },
};
</script>
