<template>
  <main>
    <sqr-hero
      title="password_change_title"
      subtitle="password_change_subtitle"
      color="primary"
      size="small"
      is-bold
    />
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-9-tablet is-4-desktop">
            <div v-if="!success">
              <div>
                <h1 class="title is-4">{{ $t('password_change_title') }}</h1>
                <sqr-input-password
                  class="field"
                  name="password_old"
                  label="password_change_password_old"
                  v-model="passwordOld"
                  :valid="!$v.passwordOld.$invalid"
                  :invalid="$v.passwordOld.$error"
                  size="medium"
                  @keyup.enter="changePassword()"
                />
                <sqr-input-password
                  class="field"
                  name="password_new"
                  label="password_change_password_new"
                  v-model="passwordNew"
                  :valid="!$v.passwordNew.$invalid"
                  :invalid="$v.passwordNew.$error"
                  size="medium"
                  @keyup.enter="changePassword()"
                />
                <sqr-input-password
                  class="field"
                  name="password_new"
                  v-model="passwordConfirm"
                  label="password_change_password_confirm"
                  :valid="!$v.passwordConfirm.$invalid"
                  :invalid="$v.passwordConfirm.$error"
                  size="medium"
                  @keyup.enter="changePassword()"
                />
                <sqr-error-note :error="error" />
                <div class="field">
                  <div class="buttons is-right">
                    <sqr-button
                      label="password_change_confirm"
                      color="primary"
                      size="medium"
                      @click="changePassword()"
                      :is-loading="loading"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="success">
              <div class="notification is-success is-size-4">
                <fa :icon="['fal', 'check-circle']" />
                &ensp;{{ $t('password_change_success') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapActions } from 'vuex';
import firebase from 'firebase/app';

import { validationMixin } from 'vuelidate';
import { required, sameAs } from 'vuelidate/lib/validators';

import SqrInputPassword from '@/sqrd/SqrInputPassword';
import SqrErrorNote from '@/sqrd/SqrErrorNote';
import SqrButton from '@/sqrd/SqrButton';

export default {
  name: 'PasswordChange',
  mixins: [validationMixin],
  components: { SqrButton, SqrInputPassword, SqrErrorNote },
  data() {
    return {
      loading: false,
      success: false,
      error: null,
      passwordOld: '',
      passwordNew: '',
      passwordConfirm: '',
    };
  },
  validations: {
    passwordOld: { required },
    passwordNew: { required },
    passwordConfirm: { required, sameAs: sameAs('passwordNew') },
  },
  methods: {
    ...mapActions('auth', ['passwordChange']),
    async changePassword() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      try {
        this.loading = true;
        const password = this.passwordNew;
        const user = this.$fb().auth().currentUser;
        const email = user.email;
        const credentials = firebase.auth.EmailAuthProvider.credential(
          email,
          this.passwordOld
        );
        await user.reauthenticateWithCredential(credentials);
        const result = await user.updatePassword(password);
        this.success = true;
        this.loading = false;
        return result;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },
  },
};
</script>
