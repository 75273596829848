<template>
  <div class="bg-yellow-100 text-yellow-900" v-if="error">
    <div class="p-2 text-sm font-medium">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'SqrErrorText',
  props: {
    error: { type: [Error, Object, String] },
  },
  computed: {
    message() {
      if (typeof this.error === 'string') {
        return this.error;
      } else if (this.error?.response?.data?.message) {
        return this.error?.response?.data?.message;
      } else {
        return this.error?.message || this.error?.toString();
      }
    },
  },
};
</script>
