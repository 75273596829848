<template>
  <main>
    <section class="section is-medium">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half">
            <div v-if="!success">
              <h1 class="title">{{ $t('password_lost_title') }}</h1>
              <error-notifications :errors="errors" />
              <div class="field">
                <sqr-input-email
                  name="email"
                  :value="email"
                  @change="fieldSet({ field: 'email', value: $event })"
                  label="password_lost_email"
                  size="large"
                  @keyup.enter="requestAndGo()"
                />
                <div class="help">{{ $t('password_lost_email_help') }}</div>
              </div>
              <div class="buttons is-right">
                <sqr-button
                  label="buttons_cancel"
                  color="white"
                  @click="$router.back()"
                  size="large"
                />
                <sqr-button
                  label="password_lost_confirm"
                  color="primary"
                  size="large"
                  @click="requestAndGo()"
                  :is-loading="loading"
                />
              </div>
            </div>
            <div v-if="success">
              <div class="notification is-success is-size-4 flex items-center">
                <span class="icon mr-4">
                  <fa :icon="['fal', 'check-circle']" />
                </span>
                <span>{{ $t('password_lost_success') }}</span>
              </div>
              <SqrErrorText :error="error" />
              <div class="is-clearfix">
                <div class="buttons is-pulled-right">
                  <sqr-router-link
                    label="buttons_back"
                    size="large"
                    color="white"
                    to-name="sign-in"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import SqrInputEmail from '@/sqrd/SqrInputEmail';
import SqrRouterLink from '@/sqrd/SqrRouterLink';
import SqrErrorText from '@/sqrd-ui/SqrErrorText';
import SqrButton from '@/sqrd/SqrButton';

import ErrorNotifications from '@/components/ErrorNotifications';

export default {
  name: 'SignPasswordLost',
  components: {
    SqrInputEmail,
    SqrButton,
    SqrRouterLink,
    SqrErrorText,
    ErrorNotifications,
  },
  computed: {
    ...mapGetters('auth', ['email', 'errors']),
  },
  data() {
    return {
      loading: false,
      success: false,
      loadError: null,
    };
  },
  methods: {
    ...mapMutations('auth', ['fieldSet']),
    async requestAndGo() {
      try {
        this.loading = true;
        const email = this.email;
        const result = await this.$fb().auth().sendPasswordResetEmail(email);
        this.success = true;
        this.loading = false;
        return result;
      } catch (error) {
        this.loadError = error;
        this.loading = false;
      }
    },
  },
};
</script>
